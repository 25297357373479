// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-events-ex-cel-js": () => import("./../../../src/pages/events/ex-cel.js" /* webpackChunkName: "component---src-pages-events-ex-cel-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-events-tr-consumer-forum-js": () => import("./../../../src/pages/events/tr-consumer-forum.js" /* webpackChunkName: "component---src-pages-events-tr-consumer-forum-js" */),
  "component---src-pages-events-workshops-js": () => import("./../../../src/pages/events/workshops.js" /* webpackChunkName: "component---src-pages-events-workshops-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-privacy-policy-newsletters-js": () => import("./../../../src/pages/privacy-policy-newsletters.js" /* webpackChunkName: "component---src-pages-privacy-policy-newsletters-js" */),
  "component---src-pages-privacy-policy-surveys-js": () => import("./../../../src/pages/privacy-policy-surveys.js" /* webpackChunkName: "component---src-pages-privacy-policy-surveys-js" */),
  "component---src-pages-services-airline-and-aircraft-manufacturer-research-consulting-js": () => import("./../../../src/pages/services/airline-and-aircraft-manufacturer-research-consulting.js" /* webpackChunkName: "component---src-pages-services-airline-and-aircraft-manufacturer-research-consulting-js" */),
  "component---src-pages-services-b-1-s-business-1-ntelligence-service-js": () => import("./../../../src/pages/services/b1s-business-1ntelligence-service.js" /* webpackChunkName: "component---src-pages-services-b-1-s-business-1-ntelligence-service-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-tailor-made-travel-retail-research-js": () => import("./../../../src/pages/services/tailor-made-travel-retail-research.js" /* webpackChunkName: "component---src-pages-services-tailor-made-travel-retail-research-js" */)
}

